// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ioh-client-web-portal-primary: mat.define-palette(mat.$indigo-palette);
$ioh-client-web-portal-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$ioh-client-web-portal-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$ioh-client-web-portal-theme: mat.define-light-theme(
  (
    color: (
      primary: $ioh-client-web-portal-primary,
      accent: $ioh-client-web-portal-accent,
      warn: $ioh-client-web-portal-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($ioh-client-web-portal-theme);

/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");
@import "~primeng/resources/themes/saga-blue/theme.css";
@import "~primeng/resources/primeng.min.css";
@import "~primeng/resources/themes/saga-blue/theme.css";
@import "~primeicons/primeicons.css";
@import "./assets/styles/colors.scss";
@import "./assets/styles/common.scss";
@import "../node_modules/primeflex/primeflex.min.css";
@import "https://cdn.jsdelivr.net/gh/lipis/flag-icons@6.6.6/css/flag-icons.min.css";

.back-arrow-container {
  position: absolute;
  top: 20px;
  left: 20px;
}
// .color-gc-blue{
//   color: $Green !important;
//     font-size: 15px;
//     font-weight: 700;
//     width: 25px;
//     height: 25px;
//     padding: 0 !important;
//     border: 2px solid $Green;
//     text-align: center;
//     border-radius: 50%;
//     margin-left: 8px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
// }
.dollar-icon {
  display: inline-block;
  vertical-align: text-bottom;
  margin-left: 8px;
}
.back-arrow {
  font-size: 1.5rem;
  width: 36px;
  height: 36px;
  padding: 0;
  color: $primaryBlue;
  border: none;
  cursor: pointer;
  display: block;
  margin-bottom: 30px;
  i {
    font-size: 20px;
  }
}
.auth-wrapper {
  width: 100%;
  height: 100vh;
  padding: 26px 20px 17px 20px;
  background: #fff;
  .gc-auth-leftPanel {
    height: 100%;
    background: #fff url(assets/images/mesh-gradient-bg.png) no-repeat center center;
    background-size: cover;
    border-radius: 16px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    .gc-idnty-container {
      padding: 0 80px;
      margin-top: 50px;
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #52525b;
      }
    }
    .gc-auth-slider {
      padding: 0 56px;
      margin-top: 60px;
    }
  }
  .gc-auth-form-container {
    width: 540px;
    max-width: 100%;
    margin: auto;
    max-height: calc(100vh - 150px);
    overflow-y: auto;
    padding: 0 5px;
    .p-inputtext {
      ::placeholder {
        color: #8f95b2;
      }
      width: 530px;
      max-width: 100%;
      height: 60px;
      border: 1px solid #d1d5db;
      border-radius: 12px !important;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #1f2937;
      padding: 0 20px;
    }
    .p-password {
      max-width: 100%;
    }
    h1 {
      font-style: normal;
      font-weight: 600;
      font-size: 48px;
      line-height: 48px;
      color: $headingText;
      margin-bottom: 32px;
      letter-spacing: -1px;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      color: #4b5563;
      margin-bottom: 32px;
    }
    a {
      color: $headingText;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      text-align: right;
    }
    .login-btn {
      width: 530px;
      height: 60px;
      max-width: 100%;
      background: $Green;
      border-radius: 8px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 30px;
      text-align: center;
      letter-spacing: -0.25px;
      color: #ffffff;
      margin-top: 62px;
      cursor: pointer;
      border: none;
      &:hover {
        background: $Greenlight;
      }
      &:focus {
        background: $GreenDark;
      }
      &:disabled {
        opacity: 0.7;
        cursor: default;
        &:hover {
          background: $Green;
        }
      }
    }
    input[type="password"]::-ms-reveal,
    input[type="password"]::-ms-clear {
      display: none;
    }
  }
}

.gc-side-nav {
  flex: 0 0 295px;
  height: 100vh;
  background: $primaryBlue;
  padding: 19px;
  position: relative;
  .gc-logo {
    display: flex;
    align-items: center;
    column-gap: 5px;
    margin: 9px 0 44px 8px;
    cursor: pointer;
    .gc-logo-icon {
      flex: 0 0 60px;
    }
  }
  .gc-user-avatar {
    display: flex;
    align-items: center;
    column-gap: 16px;
    margin-bottom: 16px;
    .gc-user-img {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      overflow: hidden;
      background: $white;
      color: $primaryBlue;
      text-align: center;
      font-size: 25px;
      font-weight: 600;
      flex: 0 0 64px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        min-width: 100%;
        min-height: 100%;
      }
    }
    .gc-user-info {
      h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: $white;
      }
      p {
        font-style: italic;
        font-weight: 400;
        font-size: 12px;
        line-height: 10px;
        color: $white;
      }
    }
  }
  .navigations {
    height: calc(100% - 150px);
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #1e30b9;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #0e1758;
    }

    ul {
      li {
        list-style: none;
        a {
          display: flex;
          align-items: center;
          column-gap: 0;
          margin-bottom: 16px;
          color: $white;
          height: 54px;
          padding: 0;
          border-radius: 12px;
          position: relative;
          border: 1px solid $primaryBlue;
          &.active {
            // background: #404d9b;
            border-color: #fff;
          }
          &:hover {
            // background: #404d9b;
            border-color: #fff;
          }
          .gc-nav-text {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 30px;
            color: $white;
            white-space: nowrap;
            overflow: hidden;
          }
          span.gc-nav-icon {
            display: flex;
            flex: 0 0 70px;
            width: 70px;
            justify-content: center;
          }
        }
      }
    }
  }
  .gc-logout-btn {
    position: absolute;
    padding: 25px 20px;
    left: 20px;
    bottom: 0;
    a {
      display: flex;
      align-items: center;
      column-gap: 16px;
      color: $white;
      font-weight: 400;
      font-size: 16px;
      line-height: 10px;
      color: $white;
      span,
      img {
        display: inline-block;
      }
    }
  }
}

.gc-side-nav {
  transition: all 0.1s;
  span.gc-nav-collapse {
    display: none;
  }
  &.side-toggle {
    flex: 0 0 110px;
    .gc-user-info,
    .gc-nav-text,
    .gc-logo-text,
    .gc-logout-text {
      position: absolute;
      width: 0;
      height: 0;
      opacity: 0;
      visibility: hidden;
      overflow: hidden;
    }
    .navigations {
      ul {
        li {
          a {
            padding: 0 0 15px 0;
            height: 64px;
            span.gc-nav-icon {
              justify-content: center;
              flex: 0 0 70px;
            }
            span.gc-nav-collapse {
              display: block;
              position: absolute;
              font-size: 10px;
              font-weight: 400;
              bottom: 7px;
              width: 70px;
              left: 0;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
.p-datatable {
  .p-datatable-wrapper {
    overflow: visible !important;
  }
}
.table-wrapper {
  background: $white;
  background: #ffffff;
  box-shadow: 0px 2px 10px 1px rgba(105, 105, 105, 0.2);
  border-radius: 12px;
  overflow: auto;
  min-height: 500px;
  .table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 28px 24px;
    h2 {
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 30px;
      letter-spacing: -2px;
      color: $headingText;
      @media (max-width: 1920px) {
        font-size: 36px;
      }
    }
    .user-label {
      height: 40px;
      background: rgba(10, 110, 217, 0.1);
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 7px;
      margin-left: 16px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: $primaryBlue;
      padding: 0 12px;
    }
    button.p-button.reload-btn {
      width: 49px;
      height: 39px !important;
      display: block;
      background: rgba(106, 206, 106, 0.1) url("./assets/images/reload.svg") no-repeat center center !important;
      border-radius: 5px !important;
      margin-right: 16px;
    }
  }
}
.table-search-wrapper {
  width: 452px;
  height: 46px;
  background: #ffffff;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  margin-right: 24px;
  overflow: hidden;
  .table-search-field {
    border: none;
    padding: 0;
    flex: 1 1 auto;
  }
  .p-inputgroup-addon {
    background: #fff;
    border: none;
    cursor: pointer;
  }
}
.field {
  .table-search-wrapper {
    height: 52px;
    margin-right: 0;
    width: 100%;
    input {
      border: none;
    }
  }
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.disable-li {
  .p-dropdown-panel {
    .p-dropdown-items {
      .p-dropdown-item {
        padding: 0;
        option {
          padding: 0.5rem 1rem;
        }
      }
    }
  }
}

.p-dialog {
  width: 545px;
  .p-dialog-footer {
    display: flex;
    justify-content: end;
    gap: 16px;
    background: #f6f8ff;
    padding: 12px;
    .p-confirm-dialog-reject {
      background: transparent;
      color: #1f2937;
      border: 1px solid #1f2937;
    }
  }
}
.countryflag {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-size: 20px 16px;
}
.assing-agncy-filed {
  flex: 1 1 auto;
  display: flex;
  .p-inputwrapper {
    border: none;
    height: 32px;
    flex: 1 1 auto;
    padding: 0 15px;
    .p-component {
      width: 100%;
      .p-inputtext {
        border: none !important;
        height: 25px !important;
        padding: 0 !important;
      }
    }
  }
  button {
    border: none;
    background: none;
    cursor: pointer;
    width: 32px;
    height: 32px;
    flex: 0 0 32px;
  }
}
.p-inputwrapper-filled {
  .p-multiselect {
    &.p-multiselect-chip {
      .p-multiselect-label {
        padding: 10px;
      }
      .p-multiselect-token {
        padding: 8px 12px;
        background: $primaryBlue;
        color: #ecf0f5;
        border-radius: 25px;
        font-size: 12px;
      }
    }
  }
}
.p-sidebar {
  .upload-soc-button {
    .p-button {
      &.p-fileupload-choose {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
        letter-spacing: -0.25px;
        color: #1f2937;
        height: 52px;
        border: 1px solid #1f2937;
        border-radius: 8px;
        background: transparent;
        padding: 0 17px;
      }
      .p-button-icon.p-button-icon-left {
        background: url(./assets/images/upload-icon.svg) no-repeat center center;
        width: 21px;
        height: 21px;
        margin: 0;
        &::before {
          display: none;
        }
      }
    }
  }
  .p-sidebar-footer {
    .button-container {
      .p-button {
        width: 186px;
        cursor: pointer;
        &:hover {
          background: #f3f4f6;
          border-color: #f3f4f6;
          color: #1f2937;
        }
      }
    }
  }
}

.table-calendar-wrapper {
  background: #fff;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  width: 100%;
  span.p-inputgroup-addon {
    border: none;
    background: #fff;
    border-radius: 17px;
    margin-left: 2px;
  }
  input[type="text"].p-inputtext {
    border: none;
    &:focus {
      box-shadow: none;
    }
  }
}

.esoc-option-menu {
  .mat-menu-content {
    .mat-menu-item {
      .mat-opi-icon {
        svg {
          stroke: transparent;
          fill: #4b5563;
        }
      }
      &:hover {
        .mat-opi-icon {
          svg {
            stroke: transparent;
            fill: #fff;
          }
        }
      }
    }
  }
}
.superAdmin-ddm.p-dropdown {
  height: 44px;
}
.tooltip-icon {
  display: block;
  width: 24px;
  height: 24px;
  img {
    display: block;
  }
}
.Verify-dob {
  .p-calendar {
    width: 100%;
    .p-inputtext {
      border: none;
      width: 100%;
      flex: 0 0 auto;
    }
  }
}
.eSocMRNToolTip {
  .p-tooltip-text {
    width: 260px;
  }
}
.episodeIDtooltip {
  .p-tooltip-text {
    width: 290px;
  }
}
.gc-table-filter {
  th {
    position: relative;
  }
  .p-dropdown.p-component {
    &.p-focus {
      box-shadow: none;
    }
    background: transparent;
    border: none;
    position: absolute;
    top: 0;
    left: 0px;
    .p-dropdown-trigger,
    .p-dropdown-label {
      visibility: hidden;
    }
  }
  .filterClass {
    position: relative;
    i {
      cursor: pointer;
    }
    .p-dropdown {
      width: 0;
      height: 0;
      .p-overlay {
        top: 25px !important;
        left: -60px !important;
        min-width: 160px !important;
        width: 160px !important;
        .p-dropdown-item {
          line-height: 20px;
          white-space: break-spaces;
          width: 100%;
          overflow-wrap: break-word;
        }
      }
    }
    &.dd-align-right {
      .p-dropdown {
        .p-overlay {
          left: auto !important;
          right: -20px !important;
        }
      }
    }
  }
}
.p-component-overlay {
  z-index: 1099 !important;
}
p-confirmdialog {
  .p-component-overlay {
    z-index: 9999 !important;
  }
}
.eSockUpdate-confirmation-dialog {
  .p-sidebar.p-sidebar-right {
    z-index: 1100 !important;
  }
}
.visitListingTable {
  .p-datatable-wrapper {
    width: 100%;
    table {
      width: max-content;
      min-width: 100%;
      @media (max-width: 1366px) {
        width: min-content;
      }
    }
  }
}
.iohUser-table,
.agencyUser-table {
  .p-datatable-wrapper {
    overflow: auto !important;
    width: 100%;
    min-height: 500px;
    table {
      min-width: 100%;
      width: max-content;
      @media (max-width: 1366px) {
        width: min-content;
      }
      .iohUser-email {
        display: inline-block;
        width: 280px;
        overflow-wrap: break-word;
      }
    }
  }
}

.submissionDetails-table {
  thead {
    tr {
      th {
        border: none !important;
        &:first-child {
          border-radius: 6px 0 0 6px;
        }
        &:last-child {
          border-radius: 0 6px 6px 0;
        }
      }
    }
  }
}

.resolved {
  color: #10b981;
}

.un-resolved {
  color: rgb(255, 89, 3);
}
.SQ-popup-conent {
  .SQ-content-inner {
    padding: 16px;
    background-color: #f6f8ff;
    border-radius: 12px;
    label {
      color: #4b5563;
      font-weight: 600;
      line-height: 30px;
      min-width: 126px;
    }
    span {
      color: #1f2937;
      font-weight: 500;
      line-break: anywhere;
      line-height: 30px;
    }
  }
}
.gc-agency-name {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #1f2937;
}
p.gc-agency-handle {
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: rgb(75 85 99 / 50%);
}
.gc-notification {
  img {
    display: block;
  }
  &.p-overlaypanel {
    border-radius: 10px;
    .p-overlaypanel-close {
      background: #2b3990;
      &:hover {
        background: #394bb7;
      }
      &:focus {
        background: #394bb7;
      }
    }
    &:after,
    &:before {
      display: none;
    }
    table {
      tr {
        th {
          color: $primaryBlue;
          font-size: 24px;
        }
        td {
          font-size: 12px;
          color: #1f2937;
          padding: 8px;
          line-height: 20px;
          .gc-notify-time {
            color: #4b5563;
            font-size: 8px;
            font-weight: 400;
            line-height: 16px;
          }
        }
        &:nth-child(even) {
          td {
            background: #f6f8ff;
          }
        }
      }
    }
  }
}
.btn-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #8f95b2;
  background: none;
  border: none;
  cursor: pointer;
}
span.password-btn {
  height: 32px;
  padding: 10px 10px;
  background: $primaryBlue;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 11px;
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  letter-spacing: -0.25px;
  color: #fff;
}
span.resend-btn {
  height: 32px;
  padding: 10px 20px;
  background: $primaryBlue;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 11px;
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  letter-spacing: -0.25px;
  color: #fff;
}
.gc-filter-TCOL {
  position: relative;
  img {
    position: absolute;
    top: 8px;
    left: 0;
  }
  .p-multiselect {
    background: transparent;
    border: none;
    width: 25px;
    .pi-chevron-down {
      display: none;
    }
    &.p-focus {
      box-shadow: none;
    }
  }
}
.clearBtn {
  background: none;
  border: none;
  padding-right: 10px;
  cursor: pointer;
}
.paginator-wrapper {
  position: sticky;
  left: 0;
}
.mat-menu-item {
  &:disabled {
    color: rgb(255 255 255 / 80%);
    background: $primaryBlue;
  }
}
.submison-patient-tbl {
  .p-datatable-table {
    width: max-content;
    min-width: 100%;
    @media (max-width: 1366px) {
      width: min-content;
    }
  }
}
.table-calendar-wrapper {
  .p-calendar {
    width: 100%;
  }
  .p-datepicker {
    left: auto !important;
    right: 0;
  }
  &.dd-leftalign {
    .p-datepicker {
      left: 0 !important;
      right: auto;
    }
  }
}
.esco-progressBar {
  .p-progressbar {
    height: 14px !important;
    width: 100%;
    margin-bottom: 20px;
    font-size: 10px;
    .p-progressbar-value {
      background-color: $primaryBlue;
    }
  }
}
.edoc-table-wrapper {
  .p-datatable-table {
    width: max-content;
    min-width: 100%;
  }
}
.typeMulitselect-dd {
  width: 50px;
  position: absolute !important;
  top: 0;
  left: 0;
  opacity: 0;
}
.p-button {
  &.eph-button {
    width: 85px;
    height: 60px;
    background: transparent;
    border: 1px solid $headingText;
    display: flex;
    flex-direction: column;
    padding: 12px 16px;
    color: $headingText;
    font-size: 14px;
    margin: 0 4px;
    gap: 5px;
    &:hover,
    &:active,
    &:focus {
      background: transparent !important;
      color: $headingText;
      border-color: $headingText;
      outline: none;
      opacity: 0.9;
    }
    &.resendButton {
      color: $red;
      border-color: $red;
      background: transparent;
      &:hover,
      &:active,
      &:focus {
        background: transparent !important;
        color: $red;
        border-color: $red;
        outline: none;
        opacity: 0.9;
      }
    }
    &.eph-send-btn {
      color: $Green;
      border-color: $Green;
      background: transparent;
      &:hover,
      &:active,
      &:focus {
        background: transparent !important;
        color: $Green;
        border-color: $Green;
        outline: none;
        opacity: 0.9;
      }
    }
  }
}
/***
  Agency User Management Bulk user upload button
****/
.bulkUpload-btn {
  width: 195px;
  border: 1px solid $headingText;
  border-radius: 6px;
  height: 46px;
  position: relative;
  display: flex;
  flex: 0 0 195px;
  align-items: center;
  .p-dropdown {
    width: 52px;
    height: 25px;
    border: none;
    .p-dropdown-trigger {
      border-left: 1px solid $headingText;
      color: $headingText;
    }
    &:hover,
    &:focus,
    &:active,
    &.p-focus {
      box-shadow: none;
      border: none;
    }
    .p-overlay {
      left: auto !important;
      right: 0;
      top: 40px !important;
    }
  }
  .p-button {
    padding: 0;
    background: transparent;
    color: $headingText;
    gap: 0;
    height: 44px;
    &:hover,
    &:focus,
    &:active {
      background: transparent;
      color: $headingText;
      outline: none;
      box-shadow: none;
    }
  }
}
.visitDetails-table {
  .p-datatable-wrapper {
    width: 100%;
    overflow: auto !important;
    max-height: calc(100vh - 360px);
    .p-datatable-table {
      width: max-content;
      min-width: 100%;
      tr {
        &:first-child {
          th {
            position: sticky;
            top: 0;
            z-index: 4;
          }
        }
      }
    }
  }
  &.oasis-QA {
    .p-datatable-table {
      max-width: 100%;
    }
  }
}
.export-btn {
  .p-button {
    background: transparent;
    border: 1px solid #2d388b;
    color: #2d388b;
    height: 46px;
    &:hover,
    &:focus,
    &:active {
      background: rgb(239, 241, 244);
      border: 1px solid #2d388b;
      color: #2d388b;
    }
  }
}
.gc-form-section {
  .p-checkbox-label {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 30px;
    color: #2b3990;
  }
}
.addUser-popup {
  .p-dialog {
    border-radius: 6px;
    overflow: hidden;
    .p-dialog-header {
      position: absolute;
      right: 0;
      top: 0;
      padding: 10px;
    }
    .p-dialog-content {
      padding: 25px;
      h3 {
        font-size: 20px;
      }
      p {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
.dob-error {
  .p-inputtext {
    border-color: $errorRed !important;
  }
}
.report-dropdown {
  .p-dropdown-label {
    display: none;
  }
}
.profile-img-req {
  span.p-button-label {
    &::after {
      content: "*";
      color: red;
    }
  }
}

.disable-calendar .p-calendar .p-datepicker {
  display: none !important;
}

.disable-input {
  opacity: 0.7;
  pointer-events: none;
}

.custom-p-toast {
  width: fit-content !important;
  opacity: 1;
}

.disable-user-edit-popup {
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(246 248 255 / 45%);
    z-index: 9999;
  }
}

.error-border {
  border: 1px solid red !important;
}

.error-border-password .p-password .p-inputtext {
  border: 1px solid red !important;
}

.c-r {
  color: red;
}
.consistency-warnings {
  .p-datatable {
    .p-datatable-table {
      border-collapse: collapse;
      .p-datatable-tbody {
        tr:nth-child(6n + 1) td {
          background-color: #fff;
          border: none;
          border-top: 1px solid #e9ecef;
        }
        tr:nth-child(6n + 2) td {
          background-color: #fff;
          border-bottom: 1px solid #e9ecef;
        }
        tr:nth-child(6n + 3) td {
          background-color: #fff;
          border: none;
        }

        tr:nth-child(6n + 4) td {
          background-color: #e2eeff;
          border: none;
          &:first-child {
            border-top-left-radius: 6px;
          }
          &:last-child {
            border-top-right-radius: 6px;
          }
        }
        tr:nth-child(6n + 5) td {
          background-color: #e2eeff;
          border: none;
          &:first-child {
            border-bottom-left-radius: 6px;
          }
          &:last-child {
            border-bottom-right-radius: 6px;
          }
        }
        tr:nth-child(6n + 6) td {
          background-color: #fff;
          border: none;
        }
      }
    }
  }
}
.p-datatable .p-datatable-tbody > tr.no-permissions td {
  background-color: rgb(233, 91, 48, 0.1) !important;
  border-color: rgb(233, 91, 48, 0.3);
}

.p-datatable .p-datatable-tbody > tr.no-activity td {
  background-color: rgb(251 191 36 / 15%) !important;
  border-color: #fbbf24;
}

.gc-filter-TCOL-right {
  position: relative;
  width: 35px;
  float: right;
  img {
    position: absolute;
    top: 8px;
    right: 10px;
  }
  .p-multiselect {
    background: transparent;
    border: none;
    width: 25px;
    .pi-chevron-down {
      display: none;
    }
    &.p-focus {
      box-shadow: none;
    }
  }
}
.p-dropdown-items-wrapper {
  &::-webkit-scrollbar {
    width: 8px !important;
    height: 8px !important;
  }
}

.btn-color-primaryBlue button {
  background: $primaryBlue;
  &:hover {
    background: $primaryBlue !important;
  }
  &:focus {
    background: $primaryBlue !important;
  }
  &:disabled {
    opacity: 0.7;
    cursor: default;
    &:hover {
      background: $primaryBlue !important;
    }
  }
}

.export-report {
  position: relative;
  width: 170px;
  span.p-dropdown-label {
    display: none;
  }
  .p-dropdown-trigger {
    display: none;
  }
  p-button {
    position: absolute;
    .p-button {
      padding: 0 22px;
    }
  }
  .p-dropdown {
    height: 46px;
    &.p-component {
      background: transparent;
      border: none;
      box-shadow: none;
    }
  }
}
.BU-error-popup {
  .p-message.p-message-error {
    position: absolute;
    top: 35px;
    right: -155px;
    z-index: 999;
  }
}

.close-icon {
  position: absolute;
  right: 22px;
}

.clear-icon {
  position: absolute;
  right: 20px;
}

.p-multiselect-panel {
  .p-multiselect-header {
    .p-multiselect-close {
      visibility: hidden;
      opacity: 0;
    }
  }
}

.w-18 {
  min-width: 18px;
  width: 18px;
}

.w-16 {
  min-width: 16px;
  width: 16px;
}

li.p-ripple.p-element.p-dropdown-item {
  min-width: 160px;
  line-height: 20px;
}
.gc-paginator {
  .p-overlay {
    bottom: 40px !important;
    top: auto !important;
    left: 0 !important;
  }
}

.input-mask-field input {
  border: none !important;
}

.input-mask-field input:focus {
  box-shadow: none !important;
}
