/**
 * Created by Inzamam Janjua on 18/05/2023.
 */


// Primary Color Palette

$primaryBlue: #0A6ED9;
$GreenBlue: #1C75BC;
$Orange: #EF896A;
$DarkSilver: #6C6D70;
$BrightYellow: #F9A51D;
$Green: #6ACE6A;
$Greenlight: #7AEC7A;
$GreenDark: #5CB85C;
$white: #fff;
$black: #000;
$red:#C66A9E;
// Standard System Colors

$errorRed: #FF3C3C;
$darkRed: #C11574;
$lightRed: #FDF2FA;
$successGreen: #10B981;
$lightGreen: #ECFAF5;
$lightOrange: #FFF2EB;
$notificationYellow: #F9A51D;
$lightBlue:rgba(226, 238, 255, 1);
$headingText: #1E38A9;
