@import "./colors.scss";
* {
  margin: 0;
  padding: 0;
  font-family: "Arial", sans-serif;
  text-decoration: none;
  outline: none;
  box-sizing: border-box;
}
img {
  max-width: 100%;
}
body {
  background: #f6f8ff;
}

/* width */
::-webkit-scrollbar {
  width: 15px;
  height: 15px;
  border-radius: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ccc;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0A6ED9;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2d388b;
}

 .sup-upload-image{
      &.p-button {
      background: transparent;
      border: 1px solid $headingText;
      color: $headingText;
      &:hover {
        background: $headingText;
        color: #fff;
        border-color: $headingText;
      }
      .p-button-icon {
        width: 21px;
        height: 21px;
        background-image: url('../images/upload.svg');
        margin: 0;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
    }
    }

.p-button {
  height: 52px;
  padding: 10px 38px;
  background: $Green;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 11px;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  letter-spacing: -0.25px;
  color: #ffffff;
  &:enabled {
    &:hover {
      background: $Greenlight;
      outline: none;
    }
    &:focus,
    &:active {
      background: $GreenDark;
      outline: none;
      box-shadow: none;
    }
  }
  &.button-sm {
    height: 46px;
    font-size: 14px;
    padding: 10px 24px;
    line-height: 22px;
  }
  &.add-icon {
    &::after {
      content: "";
      display: block;
      width: 13px;
      height: 13px;
      background: url(../images/add.svg) no-repeat center center;
    }
  }
  &:focus{
    box-shadow:none
  }
}

.p-tooltip {
  .p-tooltip-text {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: -0.25px;
    color: #344054;
    padding: 12px 16px 12px 16px;
    background: #fff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    white-space: initial;
  }
  &.p-tooltip-right {
    .p-tooltip-arrow {
      border-right-color: #fff;
    }
  }
}

.p-datatable {
  .p-datatable-thead {
    tr {
      th {
        background: #f6f8ff;
        padding: 16px 18px;
        border-bottom:none;
        .p-sortable-column-icon {
          background: url(../images/sort-icon.svg) no-repeat center center;
          width: 14px;
          height: 14px;
          opacity: 0.35;
          &:before {
            display: none;
          }
        }
        &:hover {
          .p-sortable-column-icon {
            opacity: 1;
          }
        }
        @media(max-width:1366px) {
          padding:12px 8px;
        }
      }
    }
  }
  .p-datatable-tbody {
    tr {
      td {
        background: #fff;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        color: #1f2937;
        padding: 16px 18px;
        @media(max-width:1366px) {
          padding:12px 8px;
        }
      }
    }
  }
}

.p-paginator {
  justify-content: flex-end !important;
  .p-paginator-pages {
    .p-paginator-page {
      &.p-highlight {
        background: #e4eef7;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: #2d388b;
      }
    }
  }
  .p-paginator-current {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #4b5563;
  }
  .p-paginator-first,
  .p-paginator-last {
    display: none;
  }
}
.btn-cancel {
  width: 186px;
  height: 52px;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.25px;
  color: $headingText;
  background-color: #fff;
  border: 1px solid $headingText;
  &:enabled:hover {
    background: #f3f4f6;
    color: $headingText;
    border:none;
    outline: none;
    box-shadow: none;
  }
  &:enabled:focus,
  &:enabled:active {
    background-color: #fff;
    color: $headingText;
    border: 1px solid $headingText;
    outline: none;
    box-shadow: none;
  }
}
.btn-delete{
    width: 186px;
    height: 52px;
    border: 1px solid #FF3C3C;
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.25px;
    color: #FF3C3C;
    background-color: #fff;
    &:enabled:hover{
        background: #F3F4F6;
        color: #fff;
        background:#FF3C3C;
        border: 1px solid #FF3C3C;
        outline: none;
        box-shadow: none;
    }
    &:enabled:focus,&:enabled:active{
        background-color: #FF3C3C;
        color: #fff;
        border: 1px solid #FF3C3C;
        outline: none;
        box-shadow: none;
    }
}
.sidebar-lg .p-sidebar.p-sidebar-right {
  width: 85%;
  max-width: 100%;
  .submission-poup-content{
    min-width: 1000px;
    // overflow: auto;
  }
}
.p-sidebar{
    &.p-sidebar-right{
        width: 704px;
        .gc-slide-footer{
            border-top: 1px solid #D1D5DB;
            padding-top: 20px;
        }
        .p-sidebar-header{
            background:#F6F8FF;
            height: 90px;
        }
        .gc-form-heading{
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 30px;
            letter-spacing: -1px;
            color: $headingText;
        }
        .p-sidebar-content{
            padding: 0;
            .popup-wrapper{
                position: relative;
                background: #fff;
                padding:24px;
                .gc-form-section{
                    border: 1px solid #D1D5DB;
                    border-radius: 12px;
                    margin-bottom: 16px;

                    .gc-form-section-heading {
                        border-bottom:  1px solid #D1D5DB;
                        padding: 18px 24px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        h3{
                            font-style: normal;
                            font-weight: 500;
                            font-size: 24px;
                            line-height: 30px;
                            letter-spacing: -1px;
                            color: $headingText;
                        }
                        .gc-makS-admin{
                            font-style: normal;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 16px;
                            text-align: right;
                            letter-spacing: -0.25px;
                            color: #1F2937;
                            display: flex;
                            align-items: center;
                            gap: 8px;
                        }
                    }
                    .sidebar-form{
                        padding: 25px;
                        label,.p-checkbox-label{
                            font-family: 'Montserrat';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 15px;
                            line-height: 30px;
                            color: $headingText;
                        }
                        &.app_setting{
                          label,.p-checkbox-label{
                            font-size: 12px;
                            line-height: 16px;
                          }
                        }
                        .gc-profile-icon {
                            background: #F6F8FF;
                            border-radius: 12px;
                            padding: 16px 18px;
                            position: relative;
                            display: flex;
                            justify-content: start;
                            align-items: center;
                            gap:24px;
                            margin-bottom: 32px;
                            .gc-profile-wrap {
                                background: #FFFFFF;
                                border: 1px solid #C4C4C4;
                                width: 68px;
                                height: 68px;
                                border-radius: 50%;
                                overflow: hidden;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                            .change-img-btn{
                                width: 145px;
                                height: 40px;
                                background: rgba(43, 57, 144, 0.1);
                                border-radius: 6px;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 20px;
                                color: $headingText;
                                border: none;
                                padding: 0;
                                gap:0;
                            }
                            .remove-img-btn{
                                width: 91px;
                                height: 40px;
                                background: rgba(246, 83, 83, 0.1);
                                border-radius: 6px;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 20px;
                                color: #F65353;
                                border: none;
                            }
                        }
                        .p-inputtext{
                            height: 54px;
                            border: 1px solid #D1D5DB;
                            border-radius: 6px;
                            padding:12px 16px;
                          &.p-chips-multiple-container{
                            min-height: 54px;
                            height: auto;
                            li.p-chips-token {
                              background: #2D388B !important;
                              border-radius: 50px !important;
                              color: #fff !important;
                              span.p-chips-token-icon{
                                border-radius: 50%;
                                background: white;
                                color: $headingText !important;
                                border: none !important;
                                padding: 1px;
                              }
                            }
                          }
                        }

                        .p-dropdown{
                            border-radius: 6px;
                            .p-inputtext{
                                border: none;
                            }
                        }
                        .p-multiselect{
                            height: 54px;
                            border-radius: 6px;
                        }
                        .p-inputtext:enabled:focus,.p-dropdown:not(.p-disabled).p-focus{
                            box-shadow: none;
                        }
                        .p-input-icon-left{
                            position: relative;
                            .gc-input-icon{
                                position: absolute;
                                left:18px;
                                top: 14px;
                                z-index: 9;
                            }
                            .p-inputtext{
                                padding-left: 48px;
                            }
                        }
                        .p-multiselect-label.p-placeholder{
                            height: 54px;
                            padding: 15px;
                        }
                        .gc-dialCode{
                            .p-dropdown{
                              border-radius: 6px 0 0 6px;
                              border-right: none;
                              border-color: #D1D5DB;
                              height: 54px;
                              .p-dropdown-label{
                                padding: 13px 0 13px 16px;
                              }
                            }
                            .p-inputtext{
                                border-radius: 0 6px 6px 0;
                            }
                          }
                    }
                }

            }
        }
    }
    .p-sidebar-footer{
        .p-button{
            width: 235px;
        }
    }
}

// .badge-btn {
//   width: 190px;
//   height: 54px;
//   border: 1px solid;
//   border-radius: 6px;
//   display: flex;
//   align-items: center;
//   gap: 16px;
//   padding: 0 12px;
//   &.badge-gold {
//     color: #f2c94c;
//     border-color: #f2c94c;
//   }
//   &.badge-silver {
//     color: #b9b8b7;
//     border-color: #b9b8b7;
//   }
//   &.badge-bronze {
//     color: #f2994a;
//     border-color: #f2994a;
//   }
// }

.p-checkbox {
  .p-checkbox-box {
    border-color: $headingText;
    box-shadow: none !important;
    &.p-highlight {
      background-color: #2d388b;
      border-color: #2d388b !important;
      box-shadow: none;
      &:hover,
      &:focus,
      &:active {
        background-color: #2d388b !important;
        border-color: #2d388b !important;
        box-shadow: none;
      }
    }
    &:hover,
    &:focus,
    &:active {
      background-color: #2d388b;
      border-color: #2d388b !important;
      box-shadow: none;
    }
  }
}
.p-radiobutton {
  &.p-radiobutton {
    box-shadow: none;
  }
  .p-radiobutton-box {
    background-color: #fff !important;
    border-color: #2d388b !important;
    box-shadow: none;
    &.p-focus {
      box-shadow: none;
    }
    &.p-highlight {
      background-color: #fff !important;
      border-color: #2d388b !important;
      box-shadow: none;
      .p-radiobutton-icon {
        background: #2d388b;
      }
    }
  }
}
p-password {
  &.ng-dirty {
    .p-password {
      .p-inputtext {
        border-color: #d1d5db;
      }
    }
  }
}
.mat-menu-panel{
    //width: 180px;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 8px;
    min-height: 20px !important;
    .mat-menu-content{
        padding: 0 !important;
        .mat-menu-item{
            height: 32px;
            list-style: 32px;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #4B5563;
            border-radius: 4px;
            padding: 0 10px;
            display: flex;
            align-items: center;
            gap: 13px;
            justify-content: flex-start;
            margin-bottom: 10px;
            &:last-child{
              margin-bottom: 0;
            }
            .mat-icon{
                color:#4B5563;
                margin-right: 0;
                width: 16px;
                &.inactive-icon{
                  svg{
                    width: 20px !important;
                    margin-left: -2px;
                  }
                }
            }
            .mat-opi-icon{
              display: inline-block;
              width: 16px;
              height: 16px;
              svg{
                stroke:#4B5563;
              }
            }
            &:hover{
                background: $Green;
                color: #fff;
                .mat-icon{
                    color:#fff;
                }
                .mat-opi-icon{
                  svg{
                    stroke:$white;
                  }
                }
            }
          &:disabled{
            background: $GreenDark;
            color: #fff;
            opacity: 0.5;
            .mat-icon{
              svg{
                stroke:#fff;
              }
            }
          }

        }
    }
}
.mat-icon-button{
    .mat-button-wrapper{
        .mat-icon{
            color: $headingText;
        }
    }
}
button.mat-menu-trigger {
    background: transparent;
    border: none;
    color: $headingText;
    cursor: pointer;
}
.red {
    color: #FF3C3C;
  }
.gc-agency-badge{
  margin-top: 6px;
  .p-selectbutton{
    width: 100%;
    display: flex;
    gap: 15px;
    .p-button{
      width: auto;
      background: transparent;
      border: none;
      padding: 0;
      height: auto;
      overflow: visible;
      .gc-badge-btn{
        width: 190px;
        margin-right: 0px !important;
        height: 54px;
        border: 1px solid !important;
        border-radius: 6px !important;
        display: flex;
        justify-content: start;
        padding: 14px 12px;
        &.btn-gold{
          color: #F2C94C;
          border-color: #F2C94C !important;
          svg{
            fill: #F2C94C;
          }
          &:hover{
            background:#F2C94C ;
            color: #FFF;
            svg{
              fill:#fff;
            }
          }
        }
        &.btn-silver{
          color: #B9B8B7;
          border-color: #B9B8B7 !important;
          svg{
            fill: #B9B8B7;
          }
          &:hover{
            background:#B9B8B7 ;
            color: #FFF;
            svg{
              fill:#fff;
            }
          }
        }
        &.btn-bronze{
          color: #F2994A;
          border-color: #F2994A !important;
          svg{
            fill: #F2994A;
          }
          &:hover{
            background:#F2994A ;
            color: #FFF;
            svg{
              fill:#fff;
            }
          }
        }
        span{
          display: flex;
          align-items: center;
          gap:16px
        }
      }
      &.p-highlight{
          background-color:none;
          &:hover,&:focus{
              background:transparent;
              box-shadow: none;
          }
          .gc-badge-btn{
            &.btn-gold{
              background:#F2C94C ;
              color: #FFF;
              svg{
                fill:#fff;
              }
            }
            &.btn-silver{
              background:#B9B8B7 ;
              color: #FFF;
              svg{
                fill:#fff;
              }
            }
            &.btn-bronze{
              background:#F2994A ;
              color: #FFF;
              svg{
                fill:#fff;
              }
            }
        }
      }
    }
  }
}
.errors-label {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: $darkRed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  gap: 6px;
  height: 32px;
  background: $lightRed;
  border-radius: 6px;
  flex:0 0 auto ;
  span{
    display: inline-block;
    background: $darkRed;
    border-radius: 4px;
    padding: 0 8px;
    color: #fff;
    font-size: 10px;
    line-height: 16px;
  }
}
.warnings-label {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  gap: 6px;
  height: 32px;
  background: $lightOrange;
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: $Orange;
  flex:0 0 auto ;
  span{
    display: inline-block;
    background: $Orange;
    border-radius: 4px;
    padding: 0 8px;
    color: #fff;
    font-size: 10px;
    line-height: 16px;
  }
}
.success-label {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: $successGreen;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  gap: 6px;
  height: 32px;
  background: $lightGreen;
  border-radius: 6px;
  flex:0 0 auto ;
  span{
    display: inline-block;
    background: $successGreen;
    border-radius: 4px;
    padding: 0 8px;
    color: #fff;
    font-size: 10px;
    line-height: 16px;
  }
}
.warnings2-label {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: $primaryBlue;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  gap: 6px;
  height: 32px;
  background: $lightBlue;
  border-radius: 6px;
  flex:0 0 auto ;
  span{
    display: inline-block;
    background: $primaryBlue;
    border-radius: 4px;
    padding: 0 8px;
    color: #fff;
    font-size: 10px;
    line-height: 16px;
  }
}

.p-paginator{
  &.gc-paginator{
    p-dropdown.p-element{
      order: 0;
      margin-right:20px;
      display: flex;
      align-items: center;
      .p-dropdown{
        width: 70px;
        height: 40px;
        border: 1px solid #D1D5DB;
        border-radius: 6px;
        .p-dropdown-label{
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: $headingText;

        }
      }
      &:before{
        content:'View';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #4B5563;
      }
      &:after{
        content: 'record per page';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #4B5563;
      }
    }
    .p-paginator-prev{
      order: 1;
      color: #4B5563;
      margin-right: 20px;
      .p-paginator-icon{
        display: flex;
        align-items: center;
        gap: 15px;
        &:before{
          font-size: 20px;
        }
        &:after{
          content: 'Prev';
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 17px;
          color: $headingText;
        }
      }
      &:hover{
        background: transparent !important;
        :after{
        color: $headingText;
       }
      }
      &.p-disabled{
        .p-paginator-icon{
          &:after{
            color: #4B5563;
        }
        }
      }
    }
    .p-paginator-pages{
      order: 2;
    }
    .p-paginator-next{
      order: 3;
      margin-left: 20px;
      gap:15px;
      &:before{
        font-family: 'Montserrat';
        content: 'Next';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 17px;
        color: $headingText;
        .p-paginator-icon{
          display: flex;
          align-items: center;
          gap: 15px;
          &:before{
            font-size: 20px;
          }
        }
      }
      &:hover{
        background: transparent !important;
        &:before{
          color: $headingText;
        }
      }
    }
  }
}

.field{
  label{
    display: block;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 30px;
    color: $headingText;
  }
  input[type="text"]{
    border: 1px solid #D1D5DB;
    border-radius: 6px;
    height: 50px;
    line-height: 31px;
    display: flex;
    padding: 0 15px;
    &.table-search-field{
      padding: 0;
    }
  }
  .p-calendar{
    input[type="text"]{
      padding: 0;
    }
  }
}
.p-dropdown.p-component{
  border: 1px solid #D1D5DB;
  border-radius: 6px;
  height: 50px;
  line-height: 31px;
  display: flex;
}
.p-toast {
  .p-toast-message{
    background-color: #fff;
    &.p-toast-message-success{
      background-color: #fff;
      border: none;
      color: #4B5563;
      padding: 10px;
      .p-toast-message-content{
        border-left:4px solid #10B981;
        align-items: center;
        .p-toast-message-icon{
          background:url(../images/Success.svg) no-repeat center center;
          width: 32px;
          height: 32px;
          flex: 0 0 32px;
          &::before{
            display: none;
          }
        }
      }
    }
    &.p-toast-message-error{
      background-color: #fff;
      border: none;
      color: #4B5563;
      padding: 10px;
      .p-toast-message-content{
        border-left:4px solid #FF3C3C;
        align-items: center;
        .p-toast-message-icon{
          background:url(../images/error.svg) no-repeat center center;
          width: 32px;
          height: 32px;
          flex: 0 0 32px;
          &::before{
            display: none;
          }
        }
        .p-toast-message-text{
          .p-toast-detail{
            margin:0;
          }
        }
      }
    }
    &.p-toast-message-warn{
      background-color: #fff;
      border: none;
      color: #4B5563;
      padding: 10px;
      .p-toast-message-content{
        border-left:4px solid #FBBF24;
        align-items: center;
        .p-toast-message-icon{
          background:url(../images/warning.svg) no-repeat center center;
          width: 32px;
          height: 32px;
          flex: 0 0 32px;
          &::before{
            display: none;
          }
        }
      }
    }

    .p-toast-icon-close-icon{
      color:#4B5563;
    }
    .p-toast-detail{
      margin:0 !important;
    }
  }
}
